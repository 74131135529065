<template>
  <div class="contain">
    <div class="header-logo"><img src="https://www.hscwhn.com/static/img/logo.png" alt=""></div>
    <div class="header-nav">
      <ul v-for="item in  firstClassList.itemList " :key="item.name">
        <li class="header-navLi" @click="hoverHandler(item)" @mouseleave="leaveHandler">
          {{ item.name }}
          <div v-show="show">
            <ul class="children-list clearfix">
              <li class="brick-item brick-item-m brick-item-m-2" v-for="(itam, index) in tableData" :key="index">
                <div class="figure figure-img"><img alt="Redmi K70 至尊版" :src="itam.cover" lazy="loaded"></div>
                <h3 class="title">
                  {{ itam.title }}
                </h3>
                <p class="price"><span class="num">{{ itam.showPrice }}</span>元<span>起</span></p>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="header-Search">
      <el-input style="width: 220px" placeholder="请输入" v-model="keyWord"></el-input> <el-button slot="append"
        icon="el-icon-search" @click="ToSearch"></el-button>
    </div>
    <div>
      <div class="header-logo1" @click="handleCart"><img src="../../assets/img/HS/caigouche.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      firstClassList: [],
      secondClassList: [],
      firstClass: '',
      secondClass: '',
      show: false,
      tableData: [],
      hover: false,
      cartList: [],
      keyWord: '',
    }
  },
  mounted() {
    this.getBreed()
    this.getCart()
  },
  methods: {
    //去搜索页
    ToSearch() {
      let query = {
        keyWord: this.keyWord,
      }
      this.$router.push({ path: 'supplyTypeList', query })
    },
    hoverHandler(item) {
      this.firstClass = item.id
      this.show = true;
      this.getTableData()
    },
    leaveHandler() {
      this.show = true;
    },
    // 进采购单
    handleCart() {
      this.$router.push({ path: 'supplyCart' })
    },
    getTableData() {
      let params = {
        pageIndex: 1,
        pageSize: 10,
        keyword: '',
        petType: '',
        brand: '',
        firstCategory: this.firstClass,
        secondCategory: this.secondClass

      }
      this.$http.post("/product/mall/goods/list", params).then(res => {
        if (res.code === 0) {
          this.tableData = res.data.items;
        }
      })
    },
    // 获取类目
    getBreed() {
      let params = {
        code: 'supplyproducttype',
        CompanyId: '668638e374396787c0371b60'
      }
      this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
        if (res.code === 0) {
          this.firstClassList = res.data;
          if (this.firstClass != '') {
            this.firstClassList.itemList.map((item) => {
              if (item.id == this.firstClass) {
                this.secondClassList = item
              }
            })
          }
        }
      })
    },
    // 获取采购车
    getCart() {
      this.$http.get("/product/mall/cart/list").then(res => {
        if (res.code === 0) {
          this.cartList = res.data
          console.log(res, '2323');
        }
      })
    },
  }
}
</script>
<style scoped lang="scss">
.page {
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }

  ul {
    display: flex;
    list-style-type: none;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: #475669;
  }

  .contain {
    width: 1226px;
    height: 100px;
    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;

    .header-logo {
      width: 96px;
      height: 76px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .header-nav {
      display: flex;
      align-items: center;

      li {
        margin: 0 20px;
      }

      .header-Search {
        float: right;
      }

      .header-navLi {}

      .children-list {
        width: 1226px;
        position: absolute;
        background-color: #fff;
        top: 80px;
        left: 0;
      }

      .brick-item {
        border-right: 1px solid gainsboro;
        background: #fff;
        text-align: center;
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
        font-size: 16px;
        padding: 10px 5px;

        .figure-img {
          width: 160px;
          height: 110px;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }


    }

    .header-logo1 {
      width: 56px;
      height: 56px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        position: relative;
      }
    }

    .header-cart {
      width: 250px;
      height: 300px;
      border: 1px solid gray;
      background-color: #fff;
      position: absolute;
      right: -90px;
      top: 60px;
      text-align: left;

    }

    .cartImg {
      width: 60px;
      height: 60px;
      object-fit: cover;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .cartName {
      font-size: 13px;
    }

    .header-btn {
      display: flex;
      justify-content: space-around;
      position: absolute;
      bottom: 0px;
    }
  }

}
</style>
