<template>
  <div class="page" style="width: 100%;height: 100%;overflow: hidden;">
    <supplyType></supplyType>
    <div class="content">
      <div class="productLeft">
        <div class="mdImg">
          <img :src="cover" alt="">
        </div>
        <div class="smImg">
          <div class="smImgUl">
            <ul>
              <li v-for="(item, index) of list" :key="index" @click="hanleIndex(index)">
                <img :src="item" alt="">
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="ProductRight">
        <div class="ProductTitle">{{ info.title }}</div>
        <div class="price-content">
          <div class="price-header"><span class="price-name">价格</span><span class="num-name">&nbsp;</span></div>
          <div>
            <div class="step-price-wrapper">
              <div class="step-price-item" v-for="(item, j) in priceInfos" :key="j">
                <div class="price-box"><span class="price-unit">¥</span><span class="price-text"><strong>{{
            item.price }}</strong></span></div>
                <div class="step-unit-box"><span class="unit-text sale-amount-text">{{ item.minCount }}-{{ item.maxCount
                    }}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div class="ProductType">
          <div class="price-header"><span class="price-name">分类</span><span class="num-name">&nbsp;</span></div>
          <div class="ProductTypeItem" v-if="info.applyType">{{ info.applyType.name }}</div>
        </div>
        <div class="ProductType">
          <div class="price-header"><span class="price-name">规格</span><span class="num-name">&nbsp;</span></div>
          <div class="ProductTypeItem" v-for="(a, i) in info.skuInfos" :key="i" @click="handleSku(a, i)"
            :class="tab === i ? 'active' : ''">{{ a.skuName }}/{{
            a.skuValue }}</div>
        </div>
        <div class="ProductType">
          <div class="price-header"><span class="price-name">数量</span><span class="num-name">&nbsp;</span></div>
          <el-input-number v-model="num" :min="1" :max="100000" label="描述文字" @change="handleChange"></el-input-number>
        </div>
        <el-button style="width:210px;margin: 20px;" @click="getJoin" type="primary">加入采购车</el-button>
      </div>
    </div>
    <div class="box">
      <div class="goodInfo">商品详情</div>
      <div class="goodList">
        <div class="goodItem" v-for="(item, j) in info.paramInfos" :key="j">{{ item.title }}:{{ item.value }}</div>
      </div>
      <div class="goodImg" style="height: 100%;">
          <img :src="info.detailImg" alt="">
        </div>
    </div>
  </div>
</template>
<script>
import supplyType from "./supplyType.vue";
export default {
  components: {
    supplyType
  },
  data() {
    return {
      id: this.$route.query.id,
      list: [],      //大图lgImg移动的位置
      info: {},
      cover: '',
      priceInfos: [],
      num: 1,
      skuId: '',
      tab: 0
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      let params = {
        id: this.$route.query.id
      }
      this.$http.get("/product/mall/goods/info", params).then(res => {
        console.log(res, 'info');
        if (res.code === 0) {
          this.info = res.data
          this.cover = res.data.cover
          this.list = res.data.bannerList
          this.skuId = res.data.skuInfos ? res.data.skuInfos[0].skuId : ''
          this.priceInfos = res.data.skuInfos ? res.data.skuInfos[0].priceInfos : ''

        }
      })
    },
    handleChange(num) {
      console.log(num, 'numnum');
    },
    hanleIndex(index) {
      this.cover = this.list[index]
    },
    handleSku(a, i) {
      this.tab = i
      console.log(a, ';');
      this.priceInfos = a.priceInfos
      this.skuId = a.skuId

    },
    getJoin() {
      let params = {
        goodsId: this.$route.query.id,
        skuId: this.skuId,
        count: this.num
      }
      this.$http.post("/product/mall/cart/update", params).then(res => {
        console.log(res, 'info');
        if (res.code === 0) {
          this.$message({
            message: '加入成功',
            type: 'success'
          })
        }
      })
    }
  },
}
</script>
<style scoped lang="scss">
.page {
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }

  ul {
    list-style-type: none;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: #475669;
  }


}
</style>
<style scoped>
.content {
  width: 1220px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

.content>.product {
  display: flex;
  justify-content: space-between;
}

/* 左侧大小图样式 */
.productLeft {
  width: 600px;
  display: flex;
  /* position: relative; */
}

/* 左侧中图 */
.mdImg,
.mdImg>img {
  width: 450px;
  height: 450px;
}

/* 左侧小图 */
.smImg {
  overflow: hidden;
}

.smImgUl {

  width: 370px;
  height: 450px;
  overflow: hidden;
}

.smImg ul>li {
  padding: 0 3px;
}

.smImg img {
  width: 78px;
  height: 78px;
  border: 1px solid gray;
  margin: 3px;
}



/* product右侧 */
.productRight {
  width: 600px;
  margin-left: 20px;
}

.ProductTitle {
  font-size: 18px;
  color: #000;
  font-weight: 700;
  line-height: 50px;
}

.price-content {
  width: 600px;
  display: flex;
  align-items: center;
  padding: 8px 16px 12px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #f9f9fa;
}

.price-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 60px;
  max-width: 140px;
  margin-right: 10px;
  white-space: nowrap;
}

.price-header .price-name {
  line-height: 28px;
  font-size: 14px;
  color: #999;
}

.wrapper-ver-common {
  flex: 1;
}

.step-price-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.step-price-item {
  width: 80px;
  white-space: nowrap;
  margin-left: 24px;
  min-width: 110px;
  max-width: 170px;
}

.price-box {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  color: #f40;
}

.price-unit {
  font-size: 14px;
  line-height: 22px;
  margin-right: 4px;
  font-weight: 700;
}

.step-unit-box {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
}

.unit-text {
  color: #999;
}

.ProductType {
  display: flex;
  align-items: center !important;
  margin: 10px 10px;
}

.ProductTypeItem {
  width: 50px;
  padding: 10px 20px;
  border: 1px solid gray;
  margin-left: 10px;
  font-size: 16px;
  text-align: center;
}

.active {
  border: 1px solid gold;
}

.box {
  width: 1220px;
  border: 1px solid gray;
  margin: 30px auto;
}

.goodInfo {
  font-size: 16px;
  font-weight: 600;
  color: #726e6d;
  padding: 20px 10px 17px;
  margin: 0 5px;
  border-bottom: 1px solid gray;
}

.goodList {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0;
}
.goodImg{
  width: 1220px;
  margin: 0 auto;
  height: 100%;
  img{
    width: 100%;
    height: 100%;
  }
}
</style>
